import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Sliders`}</em>{` provide a visual indication of adjustable content, where the user can move the handle along a horizontal track to increase or decrease the value.`}</p>
    <p>{`The slider in its basic form should be accompanied by a label and a number input that doubles as a display for the slider’s current value. The basic slider does `}<strong parentName="p">{`not`}</strong>{` include discrete values, as the slider represents a percentage of 0-100. In this case it is not necessary for a user to choose a specific value, but instead generally increase or decrease an input. For example, the user increases the slider amount and the volume of the music gets louder. The more complex versions should be used for selecting a specific value within a value range.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "30.027173913043477%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of volume slider",
        "title": "Example of volume slider",
        "src": "/static/5c6ae795c8661cfc7f3029f5a3487a83/fb070/slider-usage-1.png",
        "srcSet": ["/static/5c6ae795c8661cfc7f3029f5a3487a83/d6747/slider-usage-1.png 288w", "/static/5c6ae795c8661cfc7f3029f5a3487a83/09548/slider-usage-1.png 576w", "/static/5c6ae795c8661cfc7f3029f5a3487a83/fb070/slider-usage-1.png 1152w", "/static/5c6ae795c8661cfc7f3029f5a3487a83/c3e47/slider-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "30.027173913043477%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of volume slider",
        "title": "Example of volume slider",
        "src": "/static/5c6ae795c8661cfc7f3029f5a3487a83/fb070/slider-usage-1.png",
        "srcSet": ["/static/5c6ae795c8661cfc7f3029f5a3487a83/d6747/slider-usage-1.png 288w", "/static/5c6ae795c8661cfc7f3029f5a3487a83/09548/slider-usage-1.png 576w", "/static/5c6ae795c8661cfc7f3029f5a3487a83/fb070/slider-usage-1.png 1152w", "/static/5c6ae795c8661cfc7f3029f5a3487a83/c3e47/slider-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "interaction"
    }}>{`Interaction`}</h2>
    <p>{`Users can choose a numerical value by:`}</p>
    <ul>
      <li parentName="ul">{`Entering the exact value into the text field.`}</li>
      <li parentName="ul">{`Moving the slider handle with their mouse, which automatically updates the value in the text input.`}</li>
      <li parentName="ul">{`Using the ↑ ↓ ← → arrow keys automatically updates the value in the text input and moves the slider handle to the corresponding value.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`step size increment`}</strong>{` is how many increments the inputted value and slider handle will jump when using the arrow keys. Make sure to set the step size increments to reasonable values.`}</li>
      <li parentName="ul">{`↑ ↓ ← → changes the value by one step size increment. `}<em parentName="li">{`Example: Pressing `}<inlineCode parentName="em">{`→`}</inlineCode>{` changes the inputted value from 59 to 60, increasing the value by 1 unit.`}</em></li>
      <li parentName="ul"><inlineCode parentName="li">{`Shift`}</inlineCode>{` + ↑ ↓ ← → changes the value by 10 step size increments. `}<em parentName="li">{`Example: Typing`}</em>{` `}<inlineCode parentName="li">{`Shift`}</inlineCode>{` + → `}<em parentName="li">{`changes the value from 60 to 70, increasing the inputted value by 10 units.`}</em></li>
    </ul>
    <img {...{
      "src": "/slider-usage-animation-1-2a96fbaab3ebd3b9be282abfd9df91a1.gif",
      "alt": "slider interaction"
    }}></img>
    <h2 {...{
      "id": "best-practices"
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`The slider label should indicate what value the slider is changing.`}</li>
      <li parentName="ul">{`Range values are used to describe the range in numbers.`}</li>
      <li parentName="ul">{`Do not use for ranges that are extremely large `}<em parentName="li">{`i.e. 1-1000`}</em>{`.`}</li>
      <li parentName="ul">{`Do not use for ranges that are too small `}<em parentName="li">{`i.e. 1-3`}</em>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      